import { useCallback, useEffect, useState } from 'react';
import { useBooking } from '@/hooks/useBooking';
import { useIntl } from 'react-intl';
import { API_ERRORS } from '@/utils/constants';
import { WizardStep } from '@/types/WizardBooking';
import { setTrackingData } from '@/utils/tracking-data';
import { useError } from '@/contexts/ErrorProvider/ErrorProvider';
import { formatDateWithTimeslot, timeslotToTimeHHMM } from '@/utils/date-utils';
import { usePaymentErrors } from '@/components/CreditCardGuarantee/hooks/usePaymentErrors/usePaymentErrors';
import { useMutation } from '@apollo/client';
import { CreateWidgetReservationDocument, UpdateReservationIntentDocument } from '@/graphql/types.generated';
import { WidgetReservationCreateInput } from '@/graphql/client-backend.generated';
import { ReservationIntentOriginEnum } from '@/graphql/types.generated';
import {
  CardElementsValid,
  useCardElementsState,
} from '@/components/CreditCardGuarantee/hooks/useCardElementsState/useCardElementsState';
import { A_LA_CARTE_OFFER } from '@/components/ContactForm/ContactForm';
import get from 'lodash/get';
import { captureException } from '@/utils/captureException';

export type CCGInformation = {
  cardNumber: string;
  expirationDate: string;
  securityCode: string;
};

export const canSubmit = ({
  nameOnCard,
  cardElementsValid,
  createWidgetReservationLoading,
  isStripeLoading,
  isIntentReady,
}: {
  nameOnCard: string;
  cardElementsValid: CardElementsValid;
  createWidgetReservationLoading: boolean;
  isStripeLoading: boolean;
  isIntentReady: boolean;
}): boolean => {
  const isValid =
    !!nameOnCard && cardElementsValid.cardNumber && cardElementsValid.expirationDate && cardElementsValid.securityCode;
  const isNotLoading = !createWidgetReservationLoading && !isStripeLoading && isIntentReady;
  return isValid && isNotLoading;
};

export const useCreditCardGuaranteeForm = ({ reloadPaymentIntent }: { reloadPaymentIntent: () => void }) => {
  const intl = useIntl();
  const { setErrorMessage, closeError } = useError();
  const getPaymentErrorMessage = usePaymentErrors();
  const { cardElementsLoaded, setCardElementsLoaded } = useCardElementsState();
  const {
    bookingParams: {
      pax,
      time,
      date = '',
      offer,
      restaurantNewsletterOptIn,
      specialRequest,
      theForkNewsletterOptIn,
      customFields,
      customerUuid,
      areaUuid,
      crossSellUuid,
      isWaitingList,
      mealDuration,
      utm_campaign,
      utm_medium,
      utm_source,
      reservationIntentUuid,
      origin,
    },
    restaurantUuid = '',
    handleContactFormSuccess,
    handleSelectStep,
  } = useBooking();

  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);

  const [createWidgetReservation, { loading: createWidgetReservationLoading }] = useMutation(
    CreateWidgetReservationDocument,
  );
  const [updateReservationIntent] = useMutation(UpdateReservationIntentDocument);

  useEffect(() => {
    setIsFormReady(
      cardElementsLoaded.cardNumber && cardElementsLoaded.expirationDate && cardElementsLoaded.securityCode,
    );
  }, [cardElementsLoaded.cardNumber, cardElementsLoaded.expirationDate, cardElementsLoaded.securityCode]);

  const handleError = useCallback(
    (error: Parameters<typeof getPaymentErrorMessage>[0]) => {
      reloadPaymentIntent();
      setErrorMessage(getPaymentErrorMessage(error));
    },
    [reloadPaymentIntent, getPaymentErrorMessage, setErrorMessage],
  );

  const handleCCGSubmit = async (paymentGuaranteeIntentUuid: string) => {
    closeError();
    setIsStripeLoading(true);
    try {
      const ccgFormPayload: WidgetReservationCreateInput = {
        restaurantUuid,
        date: formatDateWithTimeslot(date, Number(time), { onlyDate: true }),
        time: timeslotToTimeHHMM(Number(time)),
        partySize: Number(pax),
        isWaitingList,
        customerUuid,
        areaUuid,
        theForkNewsletterOptIn,
        customFields: customFields ? JSON.parse(customFields) : undefined,
        paymentGuaranteeIntentUuid: paymentGuaranteeIntentUuid,
        offerUuid: offer && offer != A_LA_CARTE_OFFER ? offer : undefined,
        restaurantNewsletterOptIn,
        specialRequest,
        mealDuration,
        trackingData: setTrackingData({ crossSellUuid, utm_campaign, utm_medium, utm_source }),
      };
      await createWidgetReservation({
        variables: {
          input: ccgFormPayload,
        },
        onError: (error) => {
          const errorName: string = get(error, 'graphQLErrors.0.extensions.exception.data.name', '');
          // Note: when an offer validation change, it means that the availabilities changed.
          // In this case, we estimate that the availabilities are not available anymore and redirect
          // to the "no more availability" page.
          setIsStripeLoading(false);
          if (
            [API_ERRORS.OFFER_VALIDATION_FAILED, API_ERRORS.RESTAURANT_DOES_NOT_HAVE_AVAILABILITIES].includes(errorName)
          ) {
            return handleSelectStep({ pax, date, time, step: WizardStep.Error }, { onlyKeepDHP: true });
          } else {
            handleError(error);
            captureException(new Error(`There was an error creating the booking after the payment: ${error}`));
          }
        },
        onCompleted: async (data) => {
          if (reservationIntentUuid) {
            await updateReservationIntent({
              variables: {
                input: {
                  id: reservationIntentUuid,
                  paymentGuaranteeIntentUuid,
                  reservationUuid: data?.createWidgetReservation?.reservationUuid,
                  ...(origin === ReservationIntentOriginEnum.IncompleteReservationEmail && { origin }),
                },
              },
            });
          }
          handleContactFormSuccess(data?.createWidgetReservation?.reservationUuid);
          setIsStripeLoading(false);
        },
      });
    } catch (error) {
      captureException(new Error(`There was an error processing the payment: ${error}`));
      handleError(error);
      setIsStripeLoading(false);
    }
  };

  return {
    createWidgetReservationLoading,
    isFormReady,
    isStripeLoading,
    intl,
    handleCCGSubmit,
    handleError,
    setCardElementsLoaded,
  };
};
